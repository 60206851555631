/** @jsx jsx */

import React from 'react';
import { jsx, Image } from 'theme-ui';
import SEO from '../components/seo';
import Layout from '../components/layout';

import './styles.scss';

export default function Disclaimer() {
  return (
    <Layout>
      <SEO title="Disclaimer" />
      <div className="page-container mb-4">
        <div class="mt-4 mb-4">
          <h1 className="text-center">DISCLAIMER</h1>
        </div>
        <div>
          <p>
            MoonAlgo and its team members are not registered as financial
            advisors and hold no formal qualifications to give financial advice.
            Everything that is provided on website by MoonAlgo and its team
            members is purely for educational purposes only. MoonAlgo and its
            team members are not accountable or liable for any losses or
            damages. Be aware of the risks involved with trading. Trading
            involves substantial risks. You are responsible for all the risks
            you take. MoonAlgo Indicator Script and Signals provided by MoonAlgo
            team are used at your own risk. Any content provided here should not
            be construed as financial advice. It is your responsibility to
            decide which trades to execute.
          </p>
        </div>
      </div>
    </Layout>
  );
}
